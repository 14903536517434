.month-reports {
  padding-bottom: 36px;
  border-bottom: 10px solid #05CE91;
}

.month-reports .report-label {
  background-color: #FBCA32;
  cursor: pointer;
  padding: 5px 10px;
  border-radius: 5px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-weight: 600;
}

.month-report-header {
  text-align: center;
  padding: 10px;
  background: #05CE91;
  font-weight: 600;
  font-size: 18px;
  color: white;
  margin-bottom: 42px;

}

.report-button-container {
  padding-top: 28px;
}

.btn-report {
  color: white;
  background-color: #05CE91;
  font-weight: 600;
  margin: auto;
  display: block;
}

.btn-report:hover {
  background-color: #05ce92d3;
}

.month-reports .form-check {
  display: inline-grid;
}

/* The container */
.report-checkbox {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  right: 32px;
  cursor: pointer;
  /* font-size: 22px; */
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.report-checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 5px;
  margin-left: 8px;
  /* left: 0; */
  height: 25px;
  width: 25px;
  /* background-color: #eee; */
  border: 2px solid #05CE91;
}

/* On mouse-over, add a grey background color */
.report-checkbox:hover input~.checkmark {
  /* background-color: #fff; */
}

/* When the checkbox is checked, add a blue background */
.report-checkbox input:checked~.checkmark {
  /* background-color: #2196F3; */
  color: #2196F3;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.report-checkbox input:checked~.checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.report-checkbox .checkmark:after {
  left: 8px;
  top: 4px;
  width: 6px;
  height: 12px;
  border: solid #05CE91;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}