.day-overview-link {
    color: black;
    transition: background-color 0.2s ease-in-out;
}

.day-overview-link:hover {
    background-color: rgba(0, 0, 0, 0.05);
    cursor: pointer;
}

/* Day Type: Einrichtung geöffnet */
.day-type-open {
    background-color: #e6f9e6; /* Light green */
}

/* Day Type: Einrichtung geschlossen */
.day-type-closed {
    background-color: #e6f7ff; /* Light blue */
}

/* Day Type: Feiertag */
.day-type-holiday {
    background-color: #fff9e6; /* Light yellow */
}

/* Ensure buttons remain consistent */
.day-overview-link button {
    color: black;
    border-color: black;
}

.day-overview-link button:hover {
    background-color: black;
    color: white;
}