.monthDatePicker {
  background-color: transparent;
  font-size: 18px;
  font-weight: 500;
  border-top: none;
  border-right: none;
  border-left: none;
  border-radius: 0;
  cursor: pointer;
  max-width: 100px;
  padding-left: 0;
}

.weekDatePicker {
  background-color: transparent;
  font-size: 18px;
  font-weight: 500;
  border-top: none;
  border-right: none;
  border-left: none;
  border-radius: 0;
  cursor: pointer;
  max-width: 250px;
  padding-left: 0;
}

.yearDatePicker {
  background-color: transparent;
  font-size: 18px;
  font-weight: 500;
  border-top: none;
  border-right: none;
  border-left: none;
  border-radius: 0;
  cursor: pointer;
  max-width: 100px;
  padding-left: 0;
}

.dayDatePicker {
  background-color: transparent;
  font-size: 18px;
  font-weight: 500;
  border-top: none;
  border-right: none;
  border-left: none;
  border-radius: 0;
  cursor: pointer;
  max-width: 250px;
  padding-left: 0;
}
